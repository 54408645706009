import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom/dist";
import axios from "axios";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API_BASEURL, TOKEN_KEY } from "../../../constants/apis";
import useFetch from "../../../hooks/useFetch";
import sanitizeHtml from "sanitize-html";
import { createParser } from "eventsource-parser";
import DotLoadingComp from "../../../components/DotLoadingComp";

/* global document, Office, module, require */

function CreateResponse() {
  const { email_id } = useParams();
  const [emailMessage, setEmailMessage] = useState("");
  const [response, setResponse] = useState("");
  const [generating, setGenerating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [totalRes, setTotalRes] = useState();

  const postResponse = async (e) => {
    e.preventDefault();
    setLoading(true);
    let postFormData = new FormData();
    postFormData.append("email", email_id);
    postFormData.append("message", response);
    await axios
      .post(`${API_BASEURL}/ama/v2/draft/compose`, postFormData)
      .then((res) => {
        console.log(res.data);
        setTotalRes(res.data.drafts);
        setResponse();
      })
      .catch((e) => {
        console.log(e);
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { data } = useFetch(`${API_BASEURL}/ama/v2/draft/count/${email_id}`);

  useEffect(() => {
    if (data && data.drafts) {
      setTotalRes(data.drafts);
      setEmailMessage(data.email);
    }
  }, [data]);

  const AIgenerate = async (e) => {
    e.preventDefault();
    setGenerating(true);
    fetch(`${API_BASEURL}/ama/v2/openai/compose/${email_id}`).then((respo) => {
      const decoder = new TextDecoder("utf-8");
      const reader = respo.body.getReader();

      const stream = new ReadableStream({
        start(controller) {
          function push() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                setGenerating(false);
                return;
              }

              const chunkValue = decoder.decode(value);

              setResponse((prevText) => {
                const cleanedText = sanitizeHtml(prevText, {
                  allowedTags: [],
                  allowedAttributes: {},
                  allowedIframeHostnames: [],
                });

                return cleanedText + chunkValue;
              });

              controller.enqueue(value);

              return push();
            });
          }
          push();
        },
      });

      return new Response(stream, {
        headers: { "Content-Type": "text/html" },
      }).text();
    });
  };

  const naviagte = useNavigate();
  return (
    <div className="p-2 block">
      <div className="flex justify-between">
        <div>{totalRes} responses</div>
      </div>
      {totalRes > 2 && (
        <div className="text-white text-center bg-red-600 px-2  rounded-sm">
          You have write enough responses
        </div>
      )}
      <div className="mb-16 text-white decoration-white bg-gray-500/20">
        <ReactQuill
          theme="snow"
          placeholder="write response..."
          readOnly={loading}
          value={response}
          onChange={setResponse}
        />
      </div>
      <div className="flex justify-between mt-5">
        <Button
          disableElevation
          disabled={generating}
          onClick={(e) => {
            AIgenerate(e);
            // const aitest = useFetch(`${API_BASEURL}/ama/v2/openai/compose`);
          }}
          // sx={{ padding: 0 }}
          variant="contained"
        >
          {generating ? <DotLoadingComp /> : " AI Generate"}
        </Button>
        <div>
          <Button
            disableElevation
            disabled={loading}
            onClick={() => {
              naviagte(-1);
            }}
          >
            Discrd
          </Button>
          <Button
            disableElevation
            disabled={loading}
            onClick={postResponse}
            sx={{ padding: 0 }}
            variant="contained"
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
}
export default CreateResponse;
