import React from "react";
import BackButton from "../components/BackButton";

function PageNotFound() {
  return (
    <div>
      <BackButton />
      <div className="min-h-screen ">
        <div className="flex flex-col h-full justify-center items-center">
          404
        </div>
        <div className="flex flex-col h-full justify-center items-center">
          Page Not Found
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
