import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

function SharedDonePage() {
  const navigate = useNavigate();

  return (
    <div className="w-full min-h-screen justify-center items-center flex flex-col ">
      <div className="text-green-500 text-xl font-bold">
        You Shared the label successfully
      </div>
      <div className="text-sm">
        Your emails are currently in the process of syncing. This may result in
        a delay before the updates are visible on your page. Please be patient
        and feel free to revisit later; we will ensure everything is setup for
        you
      </div>
      <div className="flex items-center">
        HOME
        <IconButton
          onClick={() => {
            navigate("/", { replace: true });
          }}
          sx={{
            fontSize: 15,
          }}
        >
          <ArrowForward />
        </IconButton>
      </div>
    </div>
  );
}

export default SharedDonePage;
