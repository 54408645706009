import React from "react";
import ResponsesCard from "../../../components/ResponseCard";
import CreateResponseButton from "../../../components/CreateResponseButton";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL } from "../../../constants/apis";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";
import { useParams } from "react-router-dom";

function ReponsesList() {
  const { email_id } = useParams();

  const { data, error, loading } = useFetch(
    `${API_BASEURL}/ama/v2/draft/${email_id}`
  );

  if (loading) {
    return <LoadingComp />;
  }
  if (error) {
    return <ErrorComp error={error} />;
  }

  if (data && data.drafts.length < 1) {
    return (
      <>
        It seems no response
        <CreateResponseButton />
      </>
    );
  }

  return (
    <div>
      {data && data.drafts.length} responses
      {console.log(data)}
      {data &&
        data.drafts &&
        data.drafts.map((each) => <ResponsesCard data={each} />)}
      <CreateResponseButton />
    </div>
  );
}

export default ReponsesList;
