import { useState, useEffect } from "react";
import axios from "axios";
import { TOKEN_KEY } from "../constants/apis";

function useFetch(url, params = {}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setData(null);
    setError(null);

    const source = axios.CancelToken.source();

    axios
      .get(url, {
        cancelToken: source.token,
        params: params,
        headers: {
          Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
        },
      })
      .then((res) => {
        setData(res.data);

        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  return { data, loading, error };
}

export default useFetch;
