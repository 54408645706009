import React from "react";

function SuccessPage() {
  return (
    <div className="w-full min-h-screen justify-center items-center flex flex-col ">
      <div className="text-green-500 text-xl font-bold">SuccessPage</div>
      <div className="">You can Go back to your addin home page</div>
    </div>
  );
}

export default SuccessPage;
