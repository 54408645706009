import React from "react";
import FollowupAlertCard from "./FollowupAlertCard";

function AlertCards({ data }) {
  return (
    <div className="w-[20rem] flex flex-col items-center justify-center mt-4  mx-auto  ">
      {data &&
        data["followUp"] &&
        data["followUp"].map((each) => (
          <FollowupAlertCard
            key={each._id}
            data={data}
            alert={each}
            isActive={false}
          />
        ))}
    </div>
  );
}

export default AlertCards;
