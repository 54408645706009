import React, { useContext, useEffect, useState } from "react";
import { Tabs, Tab, Tooltip } from "@mui/material";
import EmailCard from "../../../components/EmailCard";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL } from "../../../constants/apis";
import { useParams } from "react-router-dom";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";

import AlertContext from "../../../components/context/AlertContext";
import AlertCards from "../../../components/AlertCards";

function EmailByFolder() {
  const [tabValue, setTabValue] = useState(0);

  const { followup, alertLoading } = useContext(AlertContext);

  const { folderId, emailId } = useParams();

  const { data, error, loading } = useFetch(
    `${API_BASEURL}/api/v2/folder/emails/${folderId}`
  );

  useEffect(() => {
    if (data && data.pending) {
      // setMessages(data.pending);
      setTabValue(0);
    }
  }, [data]);

  if (loading) {
    return <LoadingComp />;
  }
  if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <div>
      <div className="sticky top-0  flex flex-col z-50 bg-gray-600">
        <div className="grow">
          {data.totalPages} Pages
          <Tabs
            value={tabValue}
            onChange={(e, val) => {
              setTabValue(val);
            }}
            centered
          >
            <Tab
              label={
                <Tooltip
                  enterDelay={700}
                  title="Email awaiting a written response from Ignite Spring Assistant "
                >
                  pending
                </Tooltip>
              }
              id={0}
            />
            <Tab
              label={
                <Tooltip
                  enterDelay={700}
                  title="Email with responses crafted by Ignite Spring Assistant "
                >
                  drafted
                </Tooltip>
              }
              id={1}
            />
            <Tab
              label={
                <Tooltip enterDelay={700} title="Sent mails ">
                  sent
                </Tooltip>
              }
              id={2}
            />
          </Tabs>
        </div>
      </div>
      <div>
        {!alertLoading &&
          followup
            .filter((each) => each.email === emailId)
            .map((each) => <AlertCards data={each} key={each.email} />)}
      </div>
      {(tabValue === 0 && data.pending.length < 1) ||
      (tabValue === 1 && data.drafted.length < 1) ||
      (tabValue === 2 && data.sent.length < 1) ? (
        <div>
          <div className="flex min-h-[70vh]  flex-col items-center justify-center">
            <img
              src="https://assets.inman.com/wp-content/uploads/2014/01/empty_mailbox_shutterstock_1445454.jpg"
              alt="empty "
              className="w-24 h-26"
            />
            It seems empty
          </div>
        </div>
      ) : (
        <>
          {tabValue === 0 ? (
            data.pending.map((each) => (
              <EmailCard
                data={each}
                key={each._id}
                tab={
                  tabValue === 0 ? "PENDING" : tabValue === 1 ? "DRAFT" : "SENT"
                }
              />
            ))
          ) : tabValue === 1 ? (
            data.drafted.map((each) => (
              <EmailCard
                data={each}
                key={each._id}
                tab={
                  tabValue === 0 ? "PENDING" : tabValue === 1 ? "DRAFT" : "SENT"
                }
              />
            ))
          ) : tabValue === 2 ? (
            data.sent.map((each) => (
              <EmailCard
                data={each}
                key={each._id}
                tab={
                  tabValue === 0 ? "PENDING" : tabValue === 1 ? "DRAFT" : "SENT"
                }
              />
            ))
          ) : (
            <div></div>
          )}
        </>
      )}
    </div>
  );
}

export default EmailByFolder;
