import React from "react";

import { Tooltip } from "@mui/material";
import { Tab } from "@headlessui/react";

import SharedFolder from "./sharedfolder/SharedFolder";
import PushMails from "./pushmails/PushMails";
import Deals from "./deals/Deals";
import Alerts from "./alerts/Alerts";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function EmailByIdHomeLayout() {
  const tabs = [
    {
      tabtitle: (
        <Tooltip enterDelay={700} title="Emails sharedto Ignite Spring ">
          SHARED
        </Tooltip>
      ),
      tabpanel: <SharedFolder />,
    },
    {
      tabtitle: (
        <Tooltip
          enterDelay={700}
          title="Emails templates provided for you to send to others "
        >
          PUSH MAIL
        </Tooltip>
      ),
      tabpanel: <PushMails />,
    },
    {
      tabtitle: (
        <Tooltip
          enterDelay={700}
          title="Emails labeled for deal-related correspondence "
        >
          DEAL
        </Tooltip>
      ),
      tabpanel: <Deals />,
    },
    {
      tabtitle: (
        <Tooltip enterDelay={700} title="Notifications ">
          ALERT
        </Tooltip>
      ),
      tabpanel: <Alerts />,
    },
  ];
  return (
    <>
      <Tab.Group>
        <Tab.List className="flex sticky top-0 bg-white shadow-sm z-50">
          {tabs.map((each) => (
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full px-2 py-2 mt-5 text-sm duration-300",
                  selected
                    ? "  shadow-md bg-blue-500 text-white rounded-t-sm"
                    : "text-gray-800"
                )
              }
            >
              {each.tabtitle}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels>
          {tabs.map((each) => (
            <Tab.Panel>{each.tabpanel}</Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  );
}

export default EmailByIdHomeLayout;
