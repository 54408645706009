import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
/* global  Office */

let isOfficeInitialized = false;

const root = ReactDOM.createRoot(document.getElementById("root"));

Office.onReady(() => {
  isOfficeInitialized = true;
  root.render(
    // <React.StrictMode>
    <App isOfficeInitialized={isOfficeInitialized} />
    // </React.StrictMode>
  );
});
