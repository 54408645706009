import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import LogoutButton from "../../components/LogoutButton";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function AmaLayout() {
  const [routeName, setRouteName] = useState("");

  useEffect(() => {
    setRouteName(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-gray-600 text-white">
        <div className="min-h-screen ">
          <Outlet />
        </div>
        <LogoutButton />
      </div>
    </ThemeProvider>
  );
}

export default AmaLayout;
