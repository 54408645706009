import React, { useContext } from "react";
import AlertCards from "../../../components/AlertCards";
import AlertContext from "../../../components/context/AlertContext";
import { useParams } from "react-router-dom";

function Alerts() {
  const { alertLoading, allAlertData } = useContext(AlertContext);
  const { emailId } = useParams();

  return (
    <div>
      {!alertLoading &&
      allAlertData.filter((each) => each.email === emailId).length < 1 ? (
        <div className="min-h-[70vh]  flex w-full h-full justify-center items-center ">
          <div classname="text-center mt-10">No notification</div>
        </div>
      ) : (
        <></>
      )}
      {!alertLoading &&
        allAlertData
          .filter((each) => each.email === emailId)
          .map((each) => <AlertCards data={each} key={each.email} />)}
    </div>
  );
}

export default Alerts;
