import React from "react";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ROLE_KEY } from "../constants/apis";

function GetMatchButton({ create = false, data }) {
  const navigate = useNavigate();
  const { emailId } = useParams();
  return (
    <div className=" fixed bottom-8 right-5  shadow-lg">
      <Button
        disableElevation
        onClick={() => {
          navigate(`/ama/emailid/${emailId}/getmatch`);
        }}
        variant="contained"
        // size="small"
        sx={{ mx: 1, borderRadius: "2px" }}
      >
        CREATE MATCH
      </Button>
    </div>
  );
}

export default GetMatchButton;
