import React from "react";
import { Outlet } from "react-router-dom";
import BackButton from "../../components/BackButton";

function AmaEmailIdLayout() {
  return (
    <div className="min-h-screen bg-gray-600 text-white">
      <div className="sticky top-0  flex flex-col z-50">
        <div className="flex w-full justify-between">
          <BackButton />
          <div className="flex">
            <div>AAM</div>
            <div className="w-10 h-10 bg-gray-200 rounded-full" />
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default AmaEmailIdLayout;
