import React from "react";

function ErrorComp({ error }) {
  return (
    <div className="pt-10">
      <div className="flex w-full justify-center text-red-500">{error}</div>
      <div className="flex w-full justify-center">
        🤕 Unable to process this page can you
        <span
          className="cursor-pointer text-blue-500"
          onClick={() => {
            window.location.reload();
          }}
        >
          reload ⟳
        </span>
      </div>
    </div>
  );
}

export default ErrorComp;
