import React, { useState } from "react";
import useFetch from "../hooks/useFetch";
import { API_BASEURL, TOKEN_KEY } from "../constants/apis";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Sync } from "@mui/icons-material";
import { IconButton } from "@mui/material";

function SyncButton() {
  const { emailId } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const syncMail = (e) => {
    e.preventDefault();
    setLoading(true);
    setData(null);
    setError(null);

    const source = axios.CancelToken.source();

    axios
      .get(`${API_BASEURL}/api/v2/sync/${emailId}`, {
        cancelToken: source.token,
        headers: {
          Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
        },
      })
      .then((res) => {
        setData(res.data);

        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  return (
    <div className="sticky top-10 right-10">
      <IconButton onClick={syncMail}>
        <Sync className={loading ? "animate-spin" : ""} />
      </IconButton>
    </div>
  );
}

export default SyncButton;
