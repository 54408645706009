import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import useFetch from "../../../hooks/useFetch";
import { API_BASEURL, ROLE_KEY, WEB_URL } from "../../../constants/apis";
import LoadingComp from "../../../components/LoadingComp";
import ErrorComp from "../../../components/ErrorComp";
import EmailMarkOption from "../../../components/EmailMarkOption";

function SharedMails() {
  const { emailId } = useParams();

  const { data, loading, error } = useFetch(
    `${API_BASEURL}/api/v2/folder/emailsuser/${emailId}`
  );

  if (loading) {
    return <LoadingComp />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }
  return (
    <div className="px-5 py-4">
      {data?.pending.length < 1 && (
        <div>
          <div className="flex min-h-[70vh]  flex-col items-center justify-center">
            <img
              src="https://assets.inman.com/wp-content/uploads/2014/01/empty_mailbox_shutterstock_1445454.jpg"
              alt="empty "
              className="w-24 h-26"
            />
            It seems empty
          </div>
        </div>
      )}
      {data?.pending.map((each) => (
        <div key={each._id}>
          <div
            onClick={() => {
              window.location.href = `${WEB_URL}/email/view/${
                each._id
              }/${localStorage.getItem(ROLE_KEY)}`;
              // officeDialogHandler(`${WEB_URL}/email/view/${data._id}`);
            }}
            className={`hover:bg-gray-700 hover:shadow-md  py-2 cursor-pointer hover:rounded-md  duration-300 px-10 border-b border-gray-500/50 group ${
              (each?.followUpScheduled || each?.reminderScheduled) &&
              "bg-yellow-500/50"
            }`}
          >
            <div className="flex justify-between">
              <div>
                <div className="line-clamp-1 text-sm font-medium">
                  {each.fromName.replace(/<.*?>/g, "").substring(0, 25)}
                </div>
                <div className="line-clamp-1 text-sm text-gray-200">
                  {each.subject
                    .replace(/<[^>]*>/g, "")
                    .replace()
                    .substring(0, 40)}
                </div>
                <div className="flex line-clamp-1 text-gray-400">
                  <div className="text-xs  ">
                    {each.bodyPreview
                      .replace(/<[^>]*>/g, "")
                      .replace()
                      .substring(0, 40)}
                  </div>
                </div>
              </div>
              <div className="text-right text-[10px]">
                <div>
                  {`${moment(each.createdDateTime).format(
                    "MMM DD/YY hh:mm a"
                  )}`}
                </div>
                <div className="hidden group-hover:block">
                  <EmailMarkOption data={each} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SharedMails;
