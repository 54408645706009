import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom/dist";
import Axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ArrowSync12Regular, ChevronLeft24Filled } from "@fluentui/react-icons";
import { API_BASEURL } from "../../../constants/apis";
import DotLoadingComp from "../../../components/DotLoadingComp";
import sanitizeHtml from "sanitize-html";
import LoadingComp from "../../../components/LoadingComp";

function CreatePushmail() {
  const { emailId } = useParams();
  const [switchAi, setSwitchAi] = useState(true);
  const [regenerate, setRegenerate] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [pushing, setPushing] = useState(false);
  const [prompt, setPrompt] = useState();
  const [response, setResponse] = useState("");
  const [possibleQA, setPossibleQA] = useState("");
  const [subject, setSubject] = useState();
  const [receiver, setReceiver] = useState("");
  const [receivermail, setReceivermail] = useState("");
  const [ccMail, setCcMail] = useState();
  const [bccMail, setBccMail] = useState();
  const [note, setNote] = useState();
  const naviagte = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const postResponse = () => {
    setPushing(true);
    let postFormData = new FormData();

    // postFormData.append("va", localStorage.getItem("tokennn"));
    postFormData.append("user", emailId);
    postFormData.append("message", response);
    subject && postFormData.append("subject", subject);
    postFormData.append("receiver", receiver);
    postFormData.append("receivermail", receivermail);
    note && postFormData.append("note", note);
    ccMail && postFormData.append("cc", ccMail);
    bccMail && postFormData.append("bcc", bccMail);

    Axios.post(`${API_BASEURL}/ama/v2/pushmail/compose`, postFormData)
      .then((res) => {
        // window.location.reload();
        naviagte(-1);
      })
      .finally(() => {
        setPushing(false);
      });
  };

  const updatePushmail = () => {
    setPushing(true);
    let postFormData = new FormData();

    postFormData.append("message", response);
    subject && postFormData.append("subject", subject);
    postFormData.append("receiver", receiver);
    postFormData.append("receivermail", receivermail);
    note && postFormData.append("note", note);
    ccMail && postFormData.append("cc", ccMail);
    bccMail && postFormData.append("bcc", bccMail);

    Axios.patch(
      `${API_BASEURL}/ama/v2/pushmail/update/${params.pushmailId}`,
      postFormData
    )
      .then((res) => {
        naviagte(-1);
      })
      .finally(() => {
        setPushing(false);
      });
  };

  const pushMail = () => {
    let postFormData = new FormData();

    // postFormData.append("va", localStorage.getItem("tokennn"));
    postFormData.append("prompt", prompt);
    postFormData.append("for", receiver);
    postFormData.append("emailId", emailId);
    setPossibleQA();
    fetch(`${API_BASEURL}/ama/v2/openai/generate/pushmail`, {
      method: "POST",
      body: postFormData,
    })
      .then((respo) => {
        const decoder = new TextDecoder("utf-8");
        const reader = respo.body.getReader();
        setResponse("");
        setSwitchAi(false);
        setGenerating(false);
        setRegenerate(true);

        const stream = new ReadableStream({
          start(controller) {
            function push() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }

                const chunkValue = decoder.decode(value);

                setResponse((prevText) => {
                  const cleanedText = sanitizeHtml(prevText, {
                    allowedTags: [],
                    allowedAttributes: {},
                    allowedIframeHostnames: [],
                  });

                  return cleanedText + chunkValue;
                });

                controller.enqueue(value);

                return push();
              });
            }
            push();
          },
        });

        return new Response(stream, {
          headers: { "Content-Type": "text/html" },
        }).text();
      })
      .catch((err) => {
        const { response } = err;
        setError(response.data.error.message);
        setGenerating(false);
      });
  };

  useEffect(() => {
    if (params && params.edit && params.edit) {
      getPushmail(params.pushmailId);
    } else {
      setLoading(false);
    }
  }, []);

  const getPushmail = (id) => {
    setLoading(true);
    Axios.get(`${API_BASEURL}/ama/v2/pushmail/${id}`)
      .then((res) => {
        setResponse(res.data?.pushMails?.message);
        setSubject(res.data?.pushMails?.subject);
        setReceivermail(res.data?.pushMails?.receivermail);
        setReceiver(res.data?.pushMails?.receiver);
        setSwitchAi(false);
        // window.location.reload();
        // naviagte(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <LoadingComp />;
  }

  return (
    <div className="mb-10">
      <div className="p-6 block">
        <input
          className="my-2 py-3 px-2 w-full text-sm  ring-1 bg-transparent ring-gray-400 outline-none focus:ring-blue-500"
          value={receiver}
          placeholder="Receiver Name"
          onChange={(e) => setReceiver(e.target.value)}
        />

        <input
          className="my-2 py-3 px-2 w-full text-sm  ring-1 bg-transparent ring-gray-400 outline-none focus:ring-blue-500"
          value={receivermail}
          placeholder="Receiver Mail Address"
          onChange={(e) => setReceivermail(e.target.value)}
        />
        <input
          className="my-2 py-3 px-2 w-full text-sm  ring-1 bg-transparent ring-gray-400 outline-none focus:ring-blue-500"
          value={ccMail}
          placeholder="cc"
          onChange={(e) => setCcMail(e.target.value)}
        />
        <input
          className="my-2 py-3 px-2 w-full text-sm  ring-1 bg-transparent ring-gray-400 outline-none focus:ring-blue-500"
          value={bccMail}
          placeholder="bcc"
          onChange={(e) => setBccMail(e.target.value)}
        />

        <input
          className="my-2 py-3 px-2 w-full text-sm  ring-1 bg-transparent ring-gray-400 outline-none focus:ring-blue-500"
          value={note}
          placeholder="Note"
          onChange={(e) => setNote(e.target.value)}
        />

        <input
          className="my-2 py-3 px-2 w-full text-sm  ring-1 bg-transparent ring-gray-400 outline-none focus:ring-blue-500"
          value={subject}
          placeholder="Subject"
          onChange={(e) => setSubject(e.target.value)}
        />
        <button
          onClick={() => {
            setSwitchAi(!switchAi);
          }}
          className={`w-full justify-center px-3 py-2 rounded-md hover:shadow-md text-white duration-300 ${
            switchAi
              ? "bg-blue-500 hover:bg-blue-600"
              : "bg-green-500 hover:bg-green-600"
          }`}
        >
          {switchAi ? (
            <div>
              <ChevronLeft24Filled />
              Write manually
            </div>
          ) : (
            "Generate by AI"
          )}
        </button>

        <div className="my-2">
          {switchAi ? (
            <div>
              <textarea
                className="my-2 py-3 px-2 w-full text-sm  ring-1 bg-transparent ring-gray-400 outline-none focus:ring-blue-500"
                value={prompt}
                rows={10}
                disabled={generating}
                placeholder="Write your prompt..."
                onChange={(e) => setPrompt(e.target.value)}
              />
              <button
                onClick={() => {
                  pushMail();
                  setGenerating(true);
                }}
                disabled={generating}
                className={`w-full flex justify-center px-3 py-2 rounded-sm text-white ${
                  generating
                    ? "bg-gray-600"
                    : error
                    ? "bg-red-600"
                    : "bg-green-500 hover:green-600"
                }`}
              >
                {generating ? <DotLoadingComp /> : "Generate"}
              </button>
            </div>
          ) : (
            <ReactQuill
              disabled={generating}
              value={response}
              onChange={setResponse}
            />
          )}
        </div>

        <div className="flex">
          {regenerate && (
            <div className="flex w-full justify-center">
              <button
                disabled={generating}
                onClick={() => {
                  pushMail();
                  setResponse("");
                  setGenerating(true);
                }}
                className="border  px-4 py-1 rounded-full hover:shadow-md duration-300"
              >
                <span>
                  <ArrowSync12Regular
                    className={`${generating && "animate-spin"}`}
                  />
                </span>
                Regenerate
              </button>
            </div>
          )}
        </div>
        <div className="flex justify-end  mt-5">
          <Button
            disableElevation
            onClick={() => {
              naviagte(-1);
            }}
          >
            Discard
          </Button>
          {params && params.edit ? (
            <Button
              disableElevation
              disabled={
                receivermail.length === 0 || response.length === 0 || pushing
              }
              onClick={() => updatePushmail()}
              variant="contained"
            >
              Update
            </Button>
          ) : (
            <Button
              disableElevation
              disabled={
                receivermail.length === 0 || response.length === 0 || pushing
              }
              onClick={() => postResponse()}
              variant="contained"
            >
              Push mail
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
export default CreatePushmail;
