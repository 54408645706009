import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";

function CreatePushmailButton() {
  const [hoverFab, setHoverFab] = useState(false);
  // const [responseview, setResponseView] = useState(false);
  const navigate = useNavigate();

  const { emailId } = useParams();

  return (
    <div>
      <div>
        <div className="mt-4  fixed bottom-8 right-5">
          {/* <div
            onClick={() => {
              navigate(`/ama/emailid/${emailId}/create-pushmail`);
            }}
            className="cursor-pointer  bg-blue-600 hover:bg-blue-700 focus:bg-blue-800 text-white rounded-md   px-4 py-2 fixed bottom-8 right-5  shadow-lg duration-300"
          >
            <div> CREATE PUSHMAIL </div>
          </div> */}
          <Button
            onClick={() => {
              navigate(`/ama/emailid/${emailId}/create-pushmail`);
            }}
            variant="contained"
          >
            CREATE PUSHMAIL
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CreatePushmailButton;
