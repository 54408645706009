import React, { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  ChevronUpDown20Filled,
  Delete20Regular,
  Drafts16Regular,
  Send20Filled,
} from "@fluentui/react-icons";
import { Listbox, Transition } from "@headlessui/react";

import { API_BASEURL } from "../../constants/apis";
import LoadingComp from "../../components/LoadingComp";
import BackButton from "../../components/BackButton";
import useFetch from "../../hooks/useFetch";
import DotLoadingComp from "../../components/DotLoadingComp";

/* global document, Office, module, require */

function ComposePage() {
  const [draft, setDraft] = useState();
  const [email, setEmail] = useState();
  const [pushmail, setPushmail] = useState();

  const [sectedEmailAccount, setSectedEmailAccount] = useState();
  const [emailAccounts, setEmailAccounts] = useState([
    { email: " bereket.developer@gmail.com" },
    { email: " bereket@gmail.com" },
  ]);
  const [sending, setSending] = useState(false);
  const naviagte = useNavigate();
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  console.log(params);
  const { data, error, loading } = useFetch(
    `${API_BASEURL}/api/v2/compose`,
    params
  );

  useEffect(() => {
    if (data && params.reply === "true") {
      setDraft(data?.draft?.message);
      setEmail(data?.email);
      setEmailAccounts(data?.emailAccounts);
      setSectedEmailAccount(data?.email?.emailAccountId);
    } else if (data && params.reply === "false") {
      setPushmail(data?.pushmail);
      setDraft(data?.pushmail?.message);
      setSectedEmailAccount(data?.pushmail?.emailAccountId);
    }
  }, [data]);

  useEffect(() => {
    Axios.get(`${API_BASEURL}/foryou/self/${id}`)
      .then((res) => {
        console.log(res.data);
        naviagte(-1);
      })
      .catch((err) => console.log(err));
  }, []);

  const sendRes = () => {
    setSending(true);
    let postFormData = new FormData();

    postFormData.append("draft", draft);
    email && postFormData.append("emailMessageId", email._id);
    pushmail && postFormData.append("pushmailId", pushmail._id);

    Axios.post(`${API_BASEURL}/api/v2/compose/${params.type}`, postFormData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("secret")}`,
      },
    })
      .then((res) => {
        // Office.context.ui.messageParent(JSON.stringify({ status: "success" }));

        console.log(res.data);
      })
      .catch((err) => {
        // console.log(err);
      })
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    const editorToolbar = document.querySelector(".ql-container");

    if (editorToolbar) {
      editorToolbar.style.border = "0px solid #fff";
    }
  }, []);

  function exreactEmail(text) {
    const regex = /<([^>]+)>/;
    const match = text?.match(regex);

    return match ? match[1] : null;
  }

  if (loading) {
    return <LoadingComp />;
  }
  return (
    <div>
      <div>
        <BackButton />
        <div className="bg-white shadow-xl border rounded-md overflow-auto ">
          <div className=" mx-5 py-3">
            {params.reply === "true" ? (
              <div>
                <div className="flex items-baseline font-medium mb-4 px-4">
                  {/* <div className="mr-2 text-sm text-zinc-600 w-16">Subject:</div>
              <input
                type="text"
                value={email?.subject}
                disabled={true}
                placeholder="Subject"
                className="w-full  focus:outline-none bg-white mb-4"
              /> */}

                  {email?.subject}
                </div>
                <div className="flex text-sm text-gray-400 px-4">
                  <div className=" font-medium mr-2"> To:</div>{" "}
                  <div> {exreactEmail(email?.fromName)}</div>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex items-baseline font-medium mb-4 px-4">
                  {pushmail?.subject}
                </div>
                <div className="flex text-sm text-gray-400 px-4">
                  <div className=" font-medium mr-2"> To:</div>{" "}
                  <div> {pushmail?.receivermail}</div>
                </div>
              </div>
            )}
            <div className="mb-16">
              <ReactQuill
                placeholder="Compose your message..."
                theme="bubble"
                value={draft}
                readOnly={sending}
                // modules={{ toolbar: [] }}
                onChange={setDraft}
                style={{
                  ".ql-container": {
                    color: "red",
                    backgroundColor: "yellow",
                  },
                  ".ql-editor": {
                    color: "red",
                    backgroundColor: "yellow",
                  },
                }}
              />
            </div>
          </div>
          <div className="fixed bottom-0 z-50   w-full bg-white border-t">
            <div className="flex items-center justify-between w-full mb-6 mt-2 px-4 ">
              <Listbox>
                <Listbox.Button>
                  <div className="flex group text-sm">
                    {sectedEmailAccount?.emailId}
                    <div className="group-hover:opacity-100 opacity-0 duration-300">
                      <ChevronUpDown20Filled />
                    </div>
                  </div>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opcity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60  overflow-auto rounded-md bg-white py-1  shadow-lg ring-1 ring-black-5 focus:outline-none text-sm">
                    {emailAccounts &&
                      emailAccounts.map((each) => (
                        <Listbox.Option className="relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 hover:bg-gray-100">
                          {each?.emailId}
                        </Listbox.Option>
                      ))}{" "}
                  </Listbox.Options>
                </Transition>
              </Listbox>
              <button
                onClick={() => {
                  sendRes();
                }}
                disabled={sending}
                className={` duration-300  flex  items-center justify-center rounded-full text-white text-sm  w-28 ${
                  sending
                    ? "bg-gray-300"
                    : "bg-blue-700 border-blue-700 border hover:bg-blue-800 hover:border-blue-400 focus:bg-blue-900"
                }`}
              >
                {sending ? (
                  <DotLoadingComp />
                ) : (
                  <div className="flex items-center px-5 py-2 ">
                    <Send20Filled />

                    <div className="ml-2">Send</div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComposePage;
