import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const naviagte = useNavigate();
  return (
    <Tooltip enterDelay={700} title="Back">
      <IconButton
        onClick={() => {
          naviagte(-1);
        }}
        sx={{
          fontSize: 15,
        }}
      >
        <ArrowBack />
      </IconButton>
    </Tooltip>
  );
}

export default BackButton;
