import React from "react";

import { Tooltip } from "@mui/material";
import { Tab } from "@headlessui/react";
import SharedFolder from "../../pages/ama/sharedfolder/SharedFolder";
import PushMails from "../../pages/ama/pushmails/PushMails";
import Deals from "../../pages/ama/deals/Deals";
import Alerts from "../../pages/ama/alerts/Alerts";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AmaEmailLayout() {
  const tabs = [
    {
      tabtitle: (
        <Tooltip enterDelay={700} title="Emails sharedto Ignite Spring ">
          SHARED
        </Tooltip>
      ),
      tabpanel: <SharedFolder />,
    },
    {
      tabtitle: (
        <Tooltip
          enterDelay={700}
          title="Emails templates provided for you to send to others "
        >
          PUSH MAIL
        </Tooltip>
      ),
      tabpanel: <PushMails />,
    },
    {
      tabtitle: (
        <Tooltip
          enterDelay={700}
          title="Emails labeled for deal-related correspondence "
        >
          DEAL
        </Tooltip>
      ),
      tabpanel: <Deals />,
    },
    {
      tabtitle: (
        <Tooltip enterDelay={700} title="Notifications ">
          ALERT
        </Tooltip>
      ),
      tabpanel: <Alerts />,
    },
  ];

  return (
    <div className="bg-gray-600 text-white min-h-screen">
      <div className="sticky top-0 flex flex-col z-50  bg-gray-600">
        <div className="grow">
          <Tab.Group>
            <Tab.List className="flex sticky top-0 bg-gray-600 shadow-md z-50">
              {tabs.map((each) => (
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full px-2 py-2 mt-5 text-sm duration-300",
                      selected
                        ? "  shadow-md bg-blue-200 text-black rounded-t-sm"
                        : "text-gray-200"
                    )
                  }
                >
                  {each.tabtitle}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              {tabs.map((each) => (
                <Tab.Panel>{each.tabpanel}</Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      {/* {tabValue === 0 ? (
        <SharedFolder />
      ) : tabValue === 1 ? (
        <PushMails />
      ) : tabValue === 2 ? (
        <Deals />
      ) : tabValue === 3 ? (
        <Alerts />
      ) : (
        <Outlet />
      )} */}
      {/* <Outlet /> */}
    </div>
  );
}

export default AmaEmailLayout;
