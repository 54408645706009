import React, { createContext, useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import { API_BASEURL, ROLE_KEY, TOKEN_KEY } from "../../constants/apis";
import { useParams } from "react-router-dom";
import axios from "axios";

const AlertContext = createContext();

const AlertProvider = ({ children }) => {
  const [allAlertData, setAllAlertData] = useState([]);
  const [alerts, setAlert] = useState();
  const [followup, setFollowup] = useState([]);

  const [alertLoading, setAlertLoading] = useState(true);
  const { emailId } = useParams();
  const url =
    localStorage.getItem(ROLE_KEY) === "ama"
      ? `${API_BASEURL}/api/v2/alert/ama`
      : `${API_BASEURL}/api/v2/alert`;

  useEffect(() => {
    fetchAlertData();
  }, []);

  const fetchAlertData = () => {
    setAlertLoading(true);

    const source = axios.CancelToken.source();

    axios
      .get(url, {
        cancelToken: source.token,

        headers: {
          Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
        },
      })
      .then((res) => {
        setFollowup(res.data.followup);
      })
      .catch((err) => {
        console.log(err.message);
        console.log("ALERT FETCH ERROR");
      })
      .finally(() => {
        setAlertLoading(false);
      });
  };

  const allAlerts = useFetch(`${API_BASEURL}/api/v2/alert/followup`);

  useEffect(() => {
    if (allAlerts && allAlerts.data && allAlerts.data.followup) {
      setAllAlertData(allAlerts.data.followup);
    }
  }, [allAlerts, allAlerts.data]);

  return (
    <AlertContext.Provider
      value={{ alert, followup, alertLoading, allAlertData, fetchAlertData }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;

export { AlertContext, AlertProvider };
