import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { API_BASEURL } from "../../constants/apis";
import LoadingComp from "../../components/LoadingComp";
import ErrorComp from "../../components/ErrorComp";
import BackButton from "../../components/BackButton";
import AlertContext from "../../components/context/AlertContext";

function AmaHome() {
  const [tabValue] = useState(0);

  const { followup, alertLoading } = useContext(AlertContext);
  const { data, error, loading } = useFetch(`${API_BASEURL}/ama/v2/account`);

  const navigate = useNavigate();

  if (loading) {
    return <LoadingComp />;
  } else if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <div className="">
      <div className="flex w-full justify-between border-b border-gray-800 sticky top-0 bg-gray-600  py-4  z-50">
        <BackButton />
        <div
          onClick={() => navigate("/profile")}
          className="flex w-full justify-end hover:cursor-pointer"
        >
          <div className="flex flex-col items-end mr-2">
            <div className="text-sm"> {data?.user?.firstName} </div>
            <div className="text-xs"> {data?.user?.email} </div>
          </div>
          <div className=" w-8 h-8 mr-5 text-white flex justify-center items-center text-center bg-gray-300 rounded-full">
            {data?.user?.firstName?.substring(0, 1)}
          </div>
        </div>
      </div>
      <div className=" ">
        <div className="min-h-screen">
          <div className=" font-semibold text-lg ml-5 my-5">User accounts</div>

          {/* <div className="sticky top-0 z-40 bg-gray-600  flex justify-center border-b border-gray-500">
            <button
              onClick={() => setTabValue(0)}
              className={`mx-3 py-2 ${
                tabValue === 0 && "text-white border-b border-white font-bold"
              }`}
            >
              Gmail
            </button>
            <button
              onClick={() => setTabValue(1)}
              className={`mx-3 py-2 ${
                tabValue === 1 && "text-white border-b border-white font-bold"
              }`}
            >
              Outlook
            </button>
          </div> */}

          <div className="px-2">
            {data.emails
              .filter((each) =>
                tabValue === 1 ? each.authBy === "ms" : each.authBy === "google"
              )
              .map((da) => (
                <div className={"items-center  mx-auto "} key={da._id}>
                  <button
                    onClick={() => {
                      navigate(`/ama/emailid/${da._id} `);
                    }}
                    className="flex w-full items-center hover:bg-gray-700 hover:shadow-md shadow-gray-900 duration-300 border-b border-gray-500 py-3 px-5 hover:rounded-md"
                  >
                    <div className=" mr-3">
                      {da.authBy === "ms" ? (
                        <span>
                          <img src={da.picture} alt="ms" className="w-8 pl-4" />
                        </span>
                      ) : (
                        <span>
                          <img
                            src={da.picture}
                            alt="google"
                            className="w-8 h-8 rounded-full object-cover overflow-hidden"
                          />
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col justify-start">
                      <div className="text-start font-medium">
                        {da.displayName}
                      </div>
                      <div className="text-start text-sm text-gray-300">
                        {da.emailId}
                      </div>
                    </div>
                    {!alertLoading &&
                    followup.filter((each) => each.email === da._id).length >
                      0 ? (
                      <div className="w-5 h-5 bg-red-500 rounded-full absolute  right-10"></div>
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmaHome;
