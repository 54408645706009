import React, { useState } from "react";
import moment from "moment";

function EmailViewComp({ each, isView = false }) {
  const [open, setOpen] = useState(isView);

  return (
    <div
      className={`${
        !open && "cursor-pointer hover:shadow-md "
      }  mx-5 mt-3 py-2 border rounded shadow-sm group duration-300`}
      onClick={(e) => {
        e.preventDefault();
        setOpen(!open);
      }}
    >
      <div className="flex flex-col  justify-between px-2">
        <div className="text-l">{each?.subject}</div>
        <div className={open ? "hidden" : "block"}>
          <div className={`}text-zinc-500 line-clamp-1 text-xs`}>
            {each?.bodyPreview}
          </div>
        </div>
      </div>
      <div className={`${!open && "hidden"}`}>
        <div className="flex w-full justify-end">
          <div className="text-center text-zinc-500 text-[12px] pr-4">
            {`${moment(`${each?.sentDateTime}`).format(
              "DD MMM/YY | hh:mm:ss a"
            )}`}
          </div>
        </div>
        <div className="flex border-b bg-zinc-300/10 mb-4 py-4 px-3">
          <div>
            <div className="text-sm">{each?.fromName}</div>
            <div className="flex text-[13px]  ">
              <div className="">To : {each?.toRecipientsName[0]}</div>
            </div>
          </div>
        </div>

        <div className="px-3 ">
          {each &&
            (each.bodyType === "text/html" ? (
              <div className="px-2 text-sm text-black overflow-x-auto ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: each.bodyContent,
                  }}
                />
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: each.bodyContent.replace(/\n/g, "<br />"),
                }}
              />
              // <div>{parse(response.bodyContent)}</div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default EmailViewComp;
