/* global document, Office, module, require */

var loginDialog;
export const officeDialogHandler = async (url) => {
  window.location.href = url;
  // await Office.context.ui.displayDialogAsync(
  //   url,
  //   {
  //     height: 90,
  //     width: 60,
  //   },
  //   (result) => {
  //     if (result.status === Office.AsyncResultStatus.Failed) {
  //       console.log(result);
  //       console.log(result.status);
  //       console.log("Error from dialog");
  //     } else {
  //       loginDialog = result.value;
  //       loginDialog = result.value;
  //       loginDialog.addEventHandler(
  //         Office.EventType.DialogMessageReceived,
  //         processLoginMessage
  //       );
  //       loginDialog.addEventHandler(Office.EventType.DialogEventReceived);
  //     }
  //   }
  // );
  // const processLoginMessage = (arg) => {
  //   let messageFromDialog = JSON.parse(arg.message);
  //   if (messageFromDialog.message === "create") {
  //     // We now have a valid access tokenn.
  //     //   naviagte(`/va/res/${messageFromDialog.messageId}`);
  //     loginDialog.close();
  //   } else {
  //     loginDialog.close();
  //     console.log("Something went wrong ");
  //   }
  //   // loginDialog.close();
  // };
};
