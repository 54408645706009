import React, { useContext, useState } from "react";
import moment from "moment";
import { API_BASEURL, ROLE_KEY, TOKEN_KEY } from "../constants/apis";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AlertContext from "./context/AlertContext";

function FollowupAlertCard({ data, alert }) {
  const [isActive, setIsActive] = useState(
    new Date(alert["followupTime"]) <= new Date() && alert["active"]
  );
  const [remove, setRemove] = useState(false);
  const navigate = useNavigate();

  const { fetchAlertData } = useContext(AlertContext);

  const handleClick = async (e) => {
    e.preventDefault();
    setRemove(true);
    setIsActive(false);
    await axios
      .patch(
        `${API_BASEURL}/api/v2/alert/remove`,
        {
          followup: alert["_id"],
          email_id: alert["email_id"]["_id"],
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        fetchAlertData();
      })
      .catch((e) => {
        // setError(e.response.data.error);
      })
      .finally(() => {
        // setLoading(false);
      });

    navigate(
      `/email/view/${alert["email_id"]["_id"]}/${localStorage.getItem(
        ROLE_KEY
      )}`
    );
  };

  return (
    <div className={`px-10 py-2  ${remove ? "hidden" : "block"}`}>
      <button
        onClick={handleClick}
        disabled={!isActive}
        className={` text-sm text-black p-2 border  rounded-md cursor-pointer w-[20rem] group ${
          isActive ? "bg-red-400 shadow-lg" : "bg-gray-300"
        } `}
      >
        Followup alert for
        {moment(alert["followupTime"]).calendar()}
        <div
          className={`line-clamp-1 duration-300 text-xs ${
            isActive ? " group-hover:line-clamp-6 " : ""
          }`}
        >
          {alert?.email_id?.fromName}
          <div className="font-bold">
            Click to remove this alert and open the mail
          </div>
        </div>
      </button>
    </div>
  );
}

export default FollowupAlertCard;
