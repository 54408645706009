import React, { useState } from "react";
import { CardActions, Button, IconButton } from "@mui/material";
import {
  Send24Regular,
  Delete24Filled,
  ArrowClockwise24Regular,
  Edit24Regular,
} from "@fluentui/react-icons";
import sanitize from "sanitize-html";
import { API_BASEURL, ROLE_KEY, WEB_URL } from "../constants/apis";
import { officeDialogHandler } from "../utils/officeDialog";
import axios from "axios";
import { Wysiwyg } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

function PushMailCard({ each }) {
  const [expand, setExpand] = useState(false);

  const [deleting, setDeleting] = useState(false);

  const { emailId } = useParams();
  const navigate = useNavigate();

  const deleteRes = (draftId) => {
    setDeleting(true);

    axios
      .delete(`${API_BASEURL}/ama/v2/pushmail/remove/${draftId}`)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <div>
      <div className="w-full flex items-center justify-center mt-2  mx-auto">
        <div className="border border-gray-300/50   rounded-md hover:shadow-lg   duration-300 w-[20rem]">
          <div className="flex px-5 py-2 bg-zinc-300/20 border-b">
            <div>
              <div className="flex flex-col items-start justify-start">
                <div className="text-md font-bold">For {each.receiver}</div>
                <div className="text-sm " sx={{ fontSize: 14 }}>
                  {each.receivermail}
                </div>
              </div>
              {each.note && <div className="text-sm">Note: ${each.note} </div>}
            </div>
            {each.labels.includes("EDITED") && (
              <div className="flex ml-auto">
                <p className="text-sm text-slate-300">edited</p>
              </div>
            )}
          </div>

          <div onClick={() => setExpand(!expand)}>
            <div className="px-4 py-2  text-sm cursor-pointer hover:bg-zinc-300/10  duration-300">
              <div className={!expand ? "line-clamp-3 " : " "}>
                {sanitize(each.message, {
                  allowedTags: [],
                  allowedAttributes: {},
                  allowedIframeHostnames: [],
                })}
              </div>
            </div>
          </div>
          <CardActions
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              boxShadow: 0,
            }}
          >
            {localStorage.getItem(ROLE_KEY) === "ama" && (
              <div className="mr-auto">
                {deleting ? (
                  <ArrowClockwise24Regular className="animate-spin" />
                ) : (
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      deleteRes(each._id);
                    }}
                    aria-label="add to favorites"
                  >
                    <Delete24Filled />
                  </IconButton>
                )}
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();

                    navigate(
                      `/ama/emailid/${emailId}/create-pushmail?edit=true&pushmailId=${each._id}`
                    );
                  }}
                  aria-label="add to favorites"
                >
                  <Edit24Regular />
                </IconButton>
              </div>
            )}

            {localStorage.getItem(ROLE_KEY) === "user" && (
              <div>
                {/* <Button
                  disableElevation
                  elevation={0}
                  variant="contained"
                  onClick={() => {
                    officeDialogHandler(`${WEB_URL}/compose`);
                  }}
                  sx={{
                    boxShadow: 0,
                  }}
                  startIcon={<Send24Regular />}
                >
                  Compose
                </Button> */}
                <Button
                  disableElevation
                  elevation={0}
                  variant="contained"
                  onClick={() => {
                    officeDialogHandler(
                      `${WEB_URL}/compose?reply=false&pushmailId=${each._id}&type=pushmail`
                    );
                  }}
                  sx={{
                    boxShadow: 0,
                  }}
                  startIcon={<Wysiwyg />}
                >
                  Full view
                </Button>
              </div>
            )}
          </CardActions>
        </div>
      </div>
    </div>
  );
}

export default PushMailCard;
