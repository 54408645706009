import React, { useContext } from "react";
import AlertCards from "../../../components/AlertCards";
import AlertContext from "../../../components/context/AlertContext";
import { useParams } from "react-router-dom";

function Alerts() {
  const { followup, alertLoading, allAlertData } = useContext(AlertContext);
  const { emailId } = useParams();

  return (
    <div>
      {!alertLoading &&
      allAlertData.filter((each) => each.email === emailId).length < 1 ? (
        <div classname="text-center mt-10 flex w-full justify-center">
          No notification
        </div>
      ) : (
        <></>
      )}
      {!alertLoading &&
        allAlertData
          .filter((each) => each.email === emailId)
          .map((each) => <AlertCards data={each} key={each.email} />)}
    </div>
  );
}

export default Alerts;
