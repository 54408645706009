import React from "react";
import { Button, Tooltip } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ROLE_KEY } from "../constants/apis";

function ViewResponseButton({ create = false, data }) {
  const navigate = useNavigate();
  const { emailId } = useParams();
  return (
    <Tooltip enterDelay={700} title="View responses">
      <Button
        disableElevation
        onClick={() => {
          // navigate(
          //   `/${localStorage.getItem(ROLE_KEY)}/emailid/${emailId}/response/${
          //     data._id
          //   }`
          // );
          if (create) {
            navigate(
              `/${localStorage.getItem(
                ROLE_KEY
              )}/emailid/${emailId}/create-response/${data._id}`
            );
          } else {
            navigate(
              `/${localStorage.getItem(ROLE_KEY)}/emailid/${emailId}/response/${
                data._id
              }`
            );
          }
        }}
        variant="outlined"
        size="small"
        sx={{ mx: 1, borderRadius: "2px" }}
      >
        {create ? "Create response" : "View response"}
      </Button>
    </Tooltip>
  );
}

export default ViewResponseButton;
