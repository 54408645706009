import React, { useEffect, useState } from "react";
import BackButton from "../components/BackButton";
import useFetch from "../hooks/useFetch";
import { API_BASEURL } from "../constants/apis";
import LoadingComp from "../components/LoadingComp";
import ErrorComp from "../components/ErrorComp";
import LogoutButton from "../components/LogoutButton";

function ProfilePage() {
  const { data, loading, error } = useFetch(API_BASEURL + "/api/v2/account");
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    setUserInfo(data);
  }, [data]);

  if (loading) {
    return <LoadingComp />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }
  return (
    <div>
      <BackButton />
      <div className="flex flex-col w-full justify-center items-center">
        <div className="flex w-full justify-center hover:cursor-pointer">
          <div className=" w-12 h-12 bg-gray-300 rounded-full" />
          <div className="flex flex-col ml-5">
            <div className="text-lg">{data?.user?.firstName} </div>
            <div className="text-sm">{data?.user?.email} </div>
          </div>
        </div>
        <input
          className="w-[20rem] bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
          placeholder="Full Name"
          value={userInfo?.user?.firstName}
          name="email"
          type="email"
          required
        />
        <input
          className="w-[20rem] bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
          placeholder="email"
          value={userInfo?.user?.email}
          name="email"
          type="email"
          required
        />
        <input
          className="w-[20rem] bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
          placeholder="company"
          name="email"
          type="email"
          required
        />
        <input
          className="w-[20rem] bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
          placeholder="profession"
          name="email"
          type="email"
          required
        />
        <textarea
          rows={"4"}
          className="w-[20rem] bg-gray-100 border-gray-300 focus:border-blue-300 outline-none ring-gray-300 focus:ring-gray-500 ring-1 rounded-sm py-2 px-2 my-2 "
          placeholder="write about you"
          name="abou"
          type="abou"
          required
        />
      </div>
      <LogoutButton />
    </div>
  );
}

export default ProfilePage;
